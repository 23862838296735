@import "../bootstrap/bootstrap";

body                                                            { background: #F6F6F6; }
body nav.navbar                                                 { background: #1A6175; }

.navbar .navbar-brand                                           { color: #fff; }
.navbar .navbar-brand:hover                                     { color: #eee; }
.navbar .navbar-nav > li > a                                    { color: #fff; }
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus                              { color: #eee; }
.navbar .nav li a:hover,
.navbar .nav li a:focus                                         { background: rgba(0,0,0,.1); }
.navbar .nav .open > a                                          { background: transparent; color: #fff; }

.panel .panel-heading                                           { background: #2695B5; }
.panel .panel-heading                                           { color: #fff; font-weight: bold; }

.graphs-right                                                   { margin-top: -6px; }
.graphs > div                                                   { display: none; }

.click-table tbody tr                                           { cursor: pointer; }

.overlay                                                        { background: rgba(0,0,0,.5); bottom: 0; display: none; left: 0; position: fixed; right: 0; top: 0; z-index: 99999; }
.overlay .window                                                { background: #fff; height: 500px; left: 0; margin: auto; max-width: 800px; padding: 20px; overflow-y: scroll; position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 100%; }
.overlay .window .order                                         { display: none; }
